


















































import { computed, defineComponent, ref } from '@vue/composition-api';
import axios from 'axios';

export default defineComponent({
  name: 'MDiscordProfile',
  props: {
    avatarSource: {
      require: true,
      type: String,
      default: ''
    },
    discordUsername: {
      require: true,
      type: String,
      default: ''
    },
    isFetching: {
      require: true,
      type: Boolean,
      default: false
    },
    discordInfo: {
      require: true,
      type: Object,
      default: () => {}
    }
  },
  setup(props: any, ctx: any) {
    const discordInfoValue = computed({
      get: () => props.discordInfo,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const isJoining = ref(false);
    async function joinCommunity() {
      try {
        isJoining.value = true;
        const data = {
          discordId: discordInfoValue.value.discordId,
          userName: discordInfoValue.value.discordUsername,
          discordAccessToken: discordInfoValue.value.discordAccessToken
        };
        const resp = await axios.post(`${process.env.VUE_APP_DISCORD_JOIN}`, data).then(() => {
          window.open(
            'https://discord.com/channels/688863898686062675/738994257200939068',
            '_blank'
          );
        });
        isJoining.value = false;
      } catch (err: any) {
        if (err.response.data && err.response.data.message === 'Member already added') {
          window.open(
            'https://discord.com/channels/688863898686062675/738994257200939068',
            '_blank'
          );
        }
        isJoining.value = false;
      }
    }

    return {
      discordInfoValue,
      isJoining,
      joinCommunity
    };
  }
});
